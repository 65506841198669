/* You can add global styles to this file, and also import other style files */

:root {
  --primary: #3a5ce9;
  --secondary: #2998e3;
  --device: #f7758c;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

img {
  max-width: 100%;
}

.container {
  margin: 0 auto;
  padding: 16px;
}

// fix white-spaces: none; into checkbox labels
.mat-checkbox-layout {
  white-space: normal !important;
}

// remove margins from left and right
.mat-card-header-text {
  margin: 0 !important;
}

// Add padding-bottom into mat-card-actions
.mat-card > .mat-card-actions:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 8px !important;
}

// Remove negative margins
.mat-card-actions {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

// Add paddings and word-break into mat-card-header
.mat-card-header {
  padding: 8px 0 16px;
  word-break: break-word;
}

.mat-card-actions-align-end .mat-button:last-child,
.mat-card-actions-align-end .mat-raised-button:last-child,
.mat-card-actions-align-end .mat-stroked-button:last-child {
  margin-left: 8px !important;
}

.mat-card-actions:not(.mat-card-actions-align-end) .mat-button:first-child,
.mat-card-actions:not(.mat-card-actions-align-end) .mat-raised-button:first-child,
.mat-card-actions:not(.mat-card-actions-align-end) .mat-stroked-button:first-child {
  margin-right: 8px !important;
}

.mat-card-actions .mat-button:last-of-type,
.mat-card-actions .mat-raised-button:last-of-type,
.mat-card-actions .mat-stroked-button:last-of-type {
  margin-right: 0 !important;
}

@media print {
  mat-icon,
  .cdk-overlay-container,
  button {
    display: none !important;
  }

  a[href^='http']:not([href*='access_token'])::after {
    content: ' (' attr(href) ')';
    display: block;
  }

  canvas {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

// paddings between cells
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 4px 8px !important;
}

.mat-form-field-infix {
  width: inherit !important;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
